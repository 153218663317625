import React from "react";

import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LoginPage from "../web/pages/login/LoginPage";
import AuthComponent from "../web/components/AuthComponent";


import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import {HashRouter, Route, Switch} from "react-router-dom";
import AdministrationPage from "./pages/administration/AdministrationPage";
import VerificationPage from "./pages/verification/VerificationPage";
import VerificationTablePage from "./pages/verification/VerificationTablePage";
import MainPage from "./pages/verification/MainPage";
import VerificationTemplatesPage from "./pages/verification/template/VerificationTemplatesPage";
import PairingReportPage from "./pages/report/PairingReportPage";


class App extends React.Component {
    render() {
        return (
            <>
                <HashRouter>
                    <Switch>
                        <Route path="/login" component={LoginPage}/>
                        {/*<Route path="/reset-password" component={ResetPassword} />*/}
                        <AuthComponent>
                            <Route exact path="/" component={MainPage}/>
                            <Route exact path="/verifications" component={VerificationTablePage}/>
                            <Route exact path="/verificationTemplates" component={VerificationTemplatesPage}/>
                            <Route exact path="/verification" component={VerificationPage}/>
                            <Route exact path="/verification/:id" component={VerificationPage}/>
                            <Route exact path="/verification/:id/pairing" component={PairingReportPage}/>
                            <Route exact path="/admin" component={AdministrationPage}/>

                        </AuthComponent>
                    </Switch>
                </HashRouter>
                <ToastContainer
                    position="bottom-right"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </>
        );
    }
}

export default App;