import React, {useState} from "react";

import CustomContainer from "../../components/CustomContainer";
import Loader from "../../components/Loader";
import CardTable from "../../components/table/CardTable";
import {useParams} from "react-router-dom";
import columns from "./PairingReportTableConfig";

const PairingReportPage = (props) => {
    let {id} = useParams();

    const [loader, setLoader] = useState(false);


    return (
        <>
            <Loader loader={loader}/>
            <CustomContainer title={"Raport parowania"}>
                <CardTable
                    tittle={"Wynnik parowania"}
                    source={"verification/" + id + "/pairing"}
                    columns={columns}
                    width={12}
                    setLoader={setLoader}
                />

            </CustomContainer>

        </>
    );
};


export default PairingReportPage;