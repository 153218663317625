import React from "react";
import {getUser} from "../../helpers/jwt";
import dateUtils from "../../helpers/dateUtils";


const PrintHeader = ({title}) => {


    return (
        <div className={"print-header mb-3"}>
            <span className="text-secondary mr-1">Użytkownik</span>
            <span className="badge badge-primary mr-3">{getUser()}</span>
            <span className="text-secondary mr-1">Data</span>
            <span className="badge badge-primary mr-3">{dateUtils.getCurrentDate("-")}</span>
        </div>

    );
};

export default PrintHeader;
