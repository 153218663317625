import React from "react";


import {Container, Row} from "react-bootstrap";
import NavigationBar from "../parts/navigation/NavigationBar";
import Menu from "../parts/menu/Menu";
import GoBackButton from "./GoBackButton";
import PrintHeader from "./print/PrintHeader";

const CustomContainer = (props) => {
    return (
        <>
            <NavigationBar/>
            <div className="d-flex flex-column" id="content-wrapper">
                <div className={`${props.wrapper}-wrapper ${props.className}`}>
                    <Container fluid className="d-flex flex-column p-0">
                        <PrintHeader/>

                        {
                            props.back ? <GoBackButton url={props.back.url} title={props.back.tittle}/> : ""

                        }
                        <div className={"mb-4"}>
                            {
                                props.title ?
                                    <h3 className={`text-dark float-left`}>{props.title}</h3>
                                    : ""

                            }
                            {
                                props.subtitle ?
                                    <h6 className="text-dark float-right pt-1">{props.subtitle}</h6>
                                    : ""

                            }
                        </div>

                        <Row className={props.rowClass} id={"content-row"}>
                            {props.children}

                        </Row>
                    </Container>
                </div>
            </div>
            <Menu/>
        </>
    );
};


export default CustomContainer;