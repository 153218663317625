import * as React from "react";

const fields = {
  config: { columns: 1 },
  fields: [
    {
      name: "value",
      label: "Wartość pomiaru",
      column: 1,
      type: "number",
      scale: 2,
      mandatory: true
    },
  ]


};

export default fields;