import React, {useState} from "react";
import IconButton from "../../components/button/IconButton";
import ModalForm from "../../components/form/ModalForm";
import fields from "./SimpleValueMeasurementFormConfig";

const SimpleValueMeasurementFormConfig = ({setValue}) => {

    const [show, setShow] = useState(false)

    return (
        <>
            <IconButton
                onClick={() => setShow(true)}
                tooltip="Wprowadź pomiar ręcznie"
                variant="outline-primary"
                icon="fa-edit"
            />

            <ModalForm
                fields={fields}
                open={show}
                title={"Ręczne wprowadzenie pomiaru"}
                setOpen={setShow}
                setLoader={()=>{}}
                size={"md"}
                customSave={(form, onSaved) => {
                    setValue(form.value)
                    onSaved()
                }}
            />
        </>
    );
};

export default SimpleValueMeasurementFormConfig;
