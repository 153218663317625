import React, { useEffect, useState } from "react";


import "./Menu.css";

import { Button, Container, Nav, Navbar } from "react-bootstrap";
import {Link, useHistory, withRouter} from "react-router-dom";
import IssueCreationModal from "../../components/IssueCreationModal";
import config from "./MenuConfig";
import {getUser, hasPermission} from "../../helpers/jwt";

const toggleJsUrl = "/assets/js/toggle.js";
const logoImage = "/assets/img/mpoint_logo.png";


const Menu = (props) => {

  const [showIssueModal, setShowIssueModal] = useState(false);


  const initToggleScript = () => {
    const script = document.createElement("script");

    script.src = toggleJsUrl;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  };

  useEffect(() => {
    initToggleScript();
  }, []);


  function handleMainPage() {
    props.history.push("/");
  }


  return (
    <>
      <Navbar variant="dark" fixed="top" className="Menu__wrapper align-items-start sidebar sidebar-dark accordion">
        <Container fluid className="d-flex flex-column p-0">
          <Button variant="link" className="d-md-none rounded-circle mr-3" id="sidebarToggleTop" type="button">
            <i className="fas fa-bars" />
          </Button>
          <img src={logoImage} className="Navigation__logo mt-2" alt="main logo" onClick={handleMainPage} />

          <Nav className="nav text-light mt-4" id="accordionSidebar-1">
            {config
              .filter(c => c.permission ? hasPermission(c.permission) : true)
              .map(c =>
                <Nav.Item role="presentation">
                  <Link className="nav-link" to={c.navigate}>
                    <i className={`fas ${c.icon}`} />
                    <span>{c.tittle}</span>
                  </Link>
                </Nav.Item>
              )}
          </Nav>
          {/*<div className="text-center d-none d-md-inline">*/}
          {/*  <Button className="btn rounded-circle border-0" id="sidebarToggle" type="button">*/}
          {/*  </Button>*/}
          {/*</div>*/}
          {/*<Button variant="outline-light" onClick={() => setShowIssueModal(true)} className="text-left" type="button">*/}
          {/*  <i className="far fa-comments" />*/}
          {/*  <label className="Nav__Error_Notify">&nbsp;Zgłoś błąd</label>*/}
          {/*</Button>*/}
          <p className="version">1.1.1</p>

        </Container>
      </Navbar>n
      <IssueCreationModal show={showIssueModal} setShow={setShowIssueModal} />
    </>
  );
};

export default withRouter(Menu);