import React, {useEffect, useRef, useState} from "react";
import CustomContainer from "../../components/CustomContainer";
import Loader from "../../components/Loader";
import {Accordion, Col} from "react-bootstrap";
import AccordionItem from "../../components/AccordionItem";
import {useHistory, useParams} from "react-router-dom";
import {Actions} from "../../../client/Actions";
import ToolSetStep from "./ToolSetStep";
import ConfigurationStep from "./ConfigurationStep";
import MeasurementsStep from "./MeasurementsStep";
import IconButton from "../../components/button/IconButton";
import ConfirmButton from "../../components/button/ConfirmButton";

const VerificationPage = (props) => {
    let {id} = useParams();
    let history = useHistory();

    let tool = new URLSearchParams(props.location.search).get("tool")


    const [loader, setLoader] = useState(false);
    const [activeKey, setActiveKey] = useState();
    const [templatesExists, setTemplatesExists] = useState(false);
    const [verificationId, setVerificationId] = useState(id);
    const [completedProcesses, setCompletedProcesses] = useState();
    const [verification, setVerification] = useState();
    const [reload, setReload] = useState();
    const [confirmed, setConfirmed] = useState(false)


    const toolSetRef = useRef(null);
    const configurationRef = useRef(null);
    const measurementsRef = useRef(null);

    const [scrollTarget, setScrollTarget] = useState(null);

    const isNewVerification = () => {
        return !id;
    };

    const prepareVerificationId = () => {
        if (isNewVerification()) {
            Actions.get("verification/generate", setVerificationId);
            Actions.get("toolSetTemplate", (data) => setTemplatesExists(data.length > 0));
        }
    };

    const loadVerification = () => {
        if (verificationId && !isNewVerification()) {
            Actions.get("verification/" + verificationId, setVerification)
        }
    };

    const onSavedVerification = () => {
        history.push("/verification/" + verificationId)
    };

    const calculateCompletedProcesses = () => {
        if (isNewVerification() && !completedProcesses) {
            setCompletedProcesses({
                toolSet: false,
                configuration: false,
                measurements: false,
            });
        } else if (verification) {
            setCompletedProcesses({
                toolSet: verification.toolSet,
                configuration: verification.toolSet && verification.configuration,
                measurements: verification.completed,
            });
        }
    };

    const determineCurrentActiveKey = () => {
        if (completedProcesses) {
            if (!completedProcesses.toolSet) {
                setActiveKey("toolSet");
            } else if (!completedProcesses.configuration) {
                setActiveKey("configuration");
            } else if (!completedProcesses.measurements || tool) {
                setActiveKey("measurements");
            }
        }
    };

    const scrollToAccordionItem = (key) => {
        const refs = {
            toolSet: toolSetRef,
            configuration: configurationRef,
            measurements: measurementsRef
        };

        setScrollTarget(refs[key]);
    };

    const handleScrollAfterOpen = () => {
        if (scrollTarget && scrollTarget.current) {
            window.scrollTo({
                top: scrollTarget.current.offsetTop + 100, // Adjust the offset if needed
                behavior: "smooth"
            });
            setScrollTarget(null); // Clear the target once scrolling is done
        }
    };

    useEffect(prepareVerificationId, []);
    useEffect(loadVerification, [verificationId, reload]);
    useEffect(calculateCompletedProcesses, [verification]);
    useEffect(determineCurrentActiveKey, [completedProcesses]);
    useEffect(() => {
        scrollToAccordionItem(activeKey);
    }, [activeKey]);

    function title() {
        return <>
            {
                completedProcesses.toolSet ? "Weryfikacja" : "Nowa weryfikacja"
            }
            {
                verification && verification.completed && <IconButton
                    icon={"fa-scroll"}
                    variant={"outline-primary"}
                    size={"sm"}
                    className="btn-circle ml-2"
                    onClick={() => {
                        Actions.download(`generate-pdf/measurement/${verification.id}`, `verification-${verification.id}`);
                    }}
                    tooltip={"Pobierz raport"}
                />
            }
            {
                verification && verification.completed && verification.configuration.withMeasurements === true &&
                <IconButton
                    variant={"outline-primary"}
                    className="btn-circle ml-1"
                    onClick={() => {
                        history.push("/verification/" + verification.id + "/pairing");
                    }}
                    size={"sm"}
                    tooltip={"Raport parowania"}
                    icon={"fa-grip-lines"}
                />
            }
            {verification && verification.configuration && verification.tools.find(t => t.verified) && !verification.completed &&
                <ConfirmButton
                    variant={"outline-primary"}
                    modalSize={"md"}
                    confirmTitle={"Zakończenie weryfikacji"}
                    confirmDescription={"Czy napewno chcesz zakończyć weryfikacje?"}
                    className="ml-2"
                    onConfirm={() => {
                        Actions.post(`verification/${verification.id}/complete`, {}, loadVerification);
                    }}
                    tooltip={"Pobierz raport"}
                >Zakończ</ConfirmButton>
            }
        </>


    }


    return (
        <>
            <Loader loader={loader}/>
            {completedProcesses &&
                <CustomContainer
                    title={title()}
                    subtitle={
                        <>


                            {verification && <>
                                <span className="text-secondary mr-1">Użytkownik</span><span
                                className="badge badge-primary mr-3">{verification.creationUsername}</span>
                                <span className="text-secondary mr-1">Data</span><span
                                className="badge badge-primary mr-3">{verification.creationDate}</span>
                            </>
                            }
                            <span className="text-secondary mr-1">ID</span><span
                            className="badge badge-primary">{verificationId}</span>
                        </>
                    }
                    className={"px-4"}>

                    <Col>
                        <Accordion activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
                            <AccordionItem title={"Informację o komplecie"}
                                           eventKey={"toolSet"}
                                           activeKey={activeKey}
                                           enable={true}
                                           completed={completedProcesses.toolSet}
                                           ref={toolSetRef}
                                           onEntered={handleScrollAfterOpen}>
                                <ToolSetStep
                                    verificationId={verificationId}
                                    verification={verification}
                                    setLoader={setLoader}
                                    onSaved={onSavedVerification}
                                    templatesExists={templatesExists}
                                />
                            </AccordionItem>
                            <AccordionItem title={"Konfiguracja"}
                                           eventKey={"configuration"}
                                           activeKey={activeKey}
                                           enable={completedProcesses.toolSet}
                                           completed={completedProcesses.configuration}
                                           ref={configurationRef}
                                           onEntered={handleScrollAfterOpen}>
                                <ConfigurationStep
                                    verificationId={verificationId}
                                    verification={verification}
                                    setLoader={setLoader}
                                    onSaved={loadVerification}
                                />
                            </AccordionItem>
                            <AccordionItem title={"Pomiary"}
                                           eventKey={"measurements"}
                                           activeKey={activeKey}
                                           enable={completedProcesses.toolSet && completedProcesses.configuration}
                                           completed={completedProcesses.measurements}
                                           ref={measurementsRef}
                                           onEntered={handleScrollAfterOpen}>
                                {verification &&
                                    <MeasurementsStep
                                        verificationId={verificationId}
                                        verification={verification}
                                        reload={reload}
                                        confirmed={confirmed}
                                        setConfirmed={setConfirmed}
                                        setReload={setReload}
                                        setLoader={setLoader}
                                        toolId={tool}
                                        onSaved={loadVerification}
                                    />}
                            </AccordionItem>
                        </Accordion>
                    </Col>
                </CustomContainer>
            }
        </>
    );
};

export default VerificationPage;
