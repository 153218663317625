import * as React from "react";


const columns = [
    {
        field: "index",
        label: "LP",
        sort: "desc",
        type: "index",
        width: 53,
    },
    {
        field: "toolNumber",
        label: "Numer górnego stempla",
        sort: "desc",
        width: 53,
        parent: "upperTool"
    },
    {
        field: "labelling",
        label: "Znakowanie górnego stempla",
        sort: "desc",
        parent: "upperTool",
        width: 53,
    },
    {
        field: "upperToolMeasure",
        label: "Długość górnego stempla (mm)",
        sort: "desc",
        width: 53,
    },
    {
        field: "toolNumber",
        label: "Numer dolnego stempla",
        sort: "desc",
        parent: "lowerTool",
        width: 53,

    },
    {
        field: "labelling",
        label: "Znakowanie dolnego stempla",
        sort: "desc",
        parent: "lowerTool",
        width: 53,
    },
    {
        field: "lowerToolMeasure",
        label: "Długość dolnego stempla (mm)",
        sort: "desc",
        width: 53,
    }


];

export default columns;