const enviroments = {
  local: "http://localhost:8080",
  senacode: "https://mpoint.senacode.pl/backend",
  tsenacode: "http://test.filters.senacode.pl/backend",
  optechnika: "http://stempel.optechnika.pl/backend",
  sanofi: "https://rzesopt01-techpoint.pharma.aventis.com/api",
  demo: "http://stempel-demo.optechnika.pl/backend"
};

const utils = {
  isEquals: (f, v) => {
    return f === v;
  },
  isNonEmpty: (v) => {
    return (v == false && v !== "" || v && v !== "none" && v !== "") ? true : false;
  },
  isNonNull: (v) => {
    return (v && v !== "") ? true : false;
  },
  isNull: (v) => {
    return (v === null) ? true : false;
  },
  defaultUrl: () => {
    return enviroments[process.env.REACT_APP_ENVIRONMENT];
  },
  numberWithSpaces: (v) => {
    return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

};



export default utils;
